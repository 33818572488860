import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import axios from 'axios'
import MySqlServices from './Services/MySqlServices'
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false
Vue.use(VueI18n)

const messages = {
    en: {
        txtSearch: 'Enter Oceanshipping reference or HBL or Container number',
        btnSearch: 'Search',
        txtTSRegistered: 'Registered',
        txtTSPickup: 'Pickup',
        txtTSDeparture: 'Departure',
        txtTSArrival: 'Arrival',
        txtTSDepartureAir: 'Departure',
        txtTSArrivalAir: 'Arrival',
        txtTSDelivery: 'Delivery',
        txtShipmentDetails: 'Shipment details',
        txtCOSReference: 'COS reference:',
        txtLoadingPlace: 'Place of receipt:',
        txtLoadingPort: 'Port of loading:',
        txtUnloadingPort: 'Port of discharge:',
        txtLoadingAirport: 'Airport of loading:',
        txtUnloadingAirport: 'Airport of discharge:',
        txtUnloadingPlace: 'Place of delivery:',
        txtTransportType: 'Transport type:',
        txtContainer: 'Container:',
        txtQuantity: 'Quantity:',
        txtUnit: 'Unit:',
        txtWeight: 'Weight:',
        txtCbm: 'CBM:',
        btnBack: 'Back',
        txtShipmentNotFound: 'Shipment not found',
        txtTransportMode: 'Transport mode',
    },
    cs: {
        txtSearch: 'Vložte Oceanshipping referenci nebo HBL nebo číslo kontejneru',
        btnSearch: 'Vyhledat',
        txtTSRegistered: 'Registrace',
        txtTSPickup: 'Nakládka',
        txtTSDeparture: 'Odplutí',
        txtTSArrival: 'Připlutí',
        txtTSDepartureAir: 'Odlet',
        txtTSArrivalAir: 'Přílet',
        txtTSDelivery: 'Dodání',
        txtShipmentDetails: 'Detaily o zásilce',
        txtCOSReference: 'COS reference:',
        txtLoadingPlace: 'Místo nakládky:',
        txtLoadingPort: 'Přístav nalodění:',
        txtUnloadingPort: 'Přístav vylodění:',
        txtLoadingAirport: 'Letiště odletu:',
        txtUnloadingAirport: 'Letiště příletu:',
        txtUnloadingPlace: 'Místo vykládky:',
        txtTransportType: 'Typ přepravy:',
        txtContainer: 'Kontejner:',
        txtQuantity: 'Množství:',
        txtUnit: 'Jednotka:',
        txtWeight: 'Váha:',
        txtCbm: 'Objem (cbm):',
        btnBack: 'Zpět',
        txtShipmentNotFound: 'Zásilka nebyla nalezena',
        txtTransportMode: 'Mód přepravy',
    },
    sk: {
        txtSearch: 'Vložte Oceanshipping referenciu alebo HBL alebo číslo kontajnara',
        btnSearch: 'Vyhľadať',
        txtTSRegistered: 'Registrácia',
        txtTSPickup: 'Nakládka',
        txtTSDeparture: 'Odplávanie',
        txtTSArrival: 'Priplávanie',
        txtTSDepartureAir: 'Odlet',
        txtTSArrivalAir: 'Prílet',
        txtTSDelivery: 'Dodanie',
        txtShipmentDetails: 'Detaily o zásielke',
        txtCOSReference: 'COS referencia:',
        txtLoadingPlace: 'Miesto nakládky:',
        txtLoadingPort: 'Prístav nalodenia:',
        txtUnloadingPort: 'Prístav vylodenia:',
        txtLoadingAirport: 'Letisko odletu:',
        txtUnloadingAirport: 'Letisko príletu:',
        txtUnloadingPlace: 'Miesto vykládky:',
        txtTransportType: 'Typ prepravy:',
        txtContainer: 'Kontajner:',
        txtQuantity: 'Množstvo:',
        txtUnit: 'Jednotka:',
        txtWeight: 'Váha:',
        txtCbm: 'Objem (cbm):',
        btnBack: 'Späť',
        txtShipmentNotFound: 'Zásielka nebola nájdená',
        txtTransportMode: 'Mód prepravy',
    },
}

const preferredLanguage = navigator.language.split('-')[0]
const i18n = new VueI18n({
    locale: preferredLanguage === 'cs' ? 'cs' : preferredLanguage === 'sk' ? 'sk' : 'en', // Získání preferovaného jazyka
    messages,
})

new Vue({
    router,
    store,
    vuetify,
    i18n,
    created() {
        MySqlServices.setAuthorizationHeader(`Bearer ${store.state.token}`)
    },
    render: (h) => h(App),
}).$mount('#app')
