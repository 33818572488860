<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <v-img :src="require('../assets/cos_logo_2022_s.jpg')" class="my-3" contain height="150" />
            </v-col>
        </v-row>
        <!--<v-row justify="center">
            <v-col class="mb-5" cols="6">
                <div class="flex-center">
                    <v-radio-group v-model="selectedOption" row class="d-flex flex-column justify-center">
                        <v-radio label="Customer reference" value="1" />
                        <v-radio label="AWB/BL number" value="2" />
                        <v-radio label="Container number" value="3" />
                    </v-radio-group>
                </div>
            </v-col>
        </v-row>-->
        <v-row justify="center">
            <v-col class="mb-5" cols="6">
                <v-text-field v-model="searchValue" :label="$t('txtSearch')" @keyup.enter="search" />
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-btn color="primary" @click="search">{{ $t('btnSearch') }}</v-btn>
        </v-row>
    </v-container>
</template>

<script>
//import MySqlServices from '../Services/MySqlServices'
export default {
    name: 'Home',

    data: () => ({
        searchValue: '',
        //searchField: 'oceanshipping_reference',
        selectedOption: '1',
    }),
    methods: {
        search() {
            if (this.searchValue != '') {
                //MySqlServices.callSearch({ params: { searchField: this.searchField, searchValue: this.searchValue } }).then(({ data }) => {
                //    this.shipments = data
                //    this.isLoading = false
                //    this.firstLoading = false
                //})

                //this.$router.push({ path: '/result', query: { searchField: this.searchField, searchValue: this.searchValue } })
                this.$router.push({ path: '/result', query: { searchValue: this.searchValue } })
            } else {
                alert('Enter search value.')
            }
        },
    },
}
</script>

<style scoped>
.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
