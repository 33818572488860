<template>
    <v-app>
        <v-app-bar app color="primary" dark height="50">
            <v-toolbar-title>COS Tracing (BETA)</v-toolbar-title>
            <div class="d-flex align-center">
                <!--<v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="@/assets/logo_COS_2.svg"
                    transition="scale-transition"
                    height="70"
                />-->

                <!--<v-img
                    alt="Vuetify Name"
                    class="shrink mt-1 hidden-sm-and-down"
                    contain
                    min-width="100"
                    src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
                    width="100"
                />-->
            </div>

            <v-spacer></v-spacer>

            <!--<v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
                <span class="mr-2">Latest Release</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>-->
            <!--<v-btn text @click="toggleLanguage">{{ currentLanguage }}</v-btn>-->
            <div class="d-flex flex-nowrap max-w">
                <v-btn min-width="0" width="30" text @click="switchLanguage('cs')">CZ</v-btn>
                <v-btn min-width="0" width="30" text @click="switchLanguage('sk')">SK</v-btn>
                <v-btn min-width="0" width="30" text @click="switchLanguage('en')">EN</v-btn>
            </div>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
        <v-footer app color="primary lighten-1" padless>
            <v-layout justify-center wrap>
                <!--<v-btn v-for="link in links" :key="`${link.label}-footer-link`" color="white" text rounded class="my-2" :to="link.url">{{
                    link.label
                }}</v-btn>-->
                <v-flex primary lighten-2 py-4 text-center white--text xs12>
                    {{ new Date().getFullYear() }} —
                    <strong>CZECHOSLOVAK OCEAN SHIPPING (v1.1)</strong>
                </v-flex>
            </v-layout>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        //languages: ['en', 'cz'],
        //currentLanguage: 'cz',
        API_URL: process.env.VUE_APP_API_URL,
    }),
    methods: {
        switchLanguage(lang) {
            this.$i18n.locale = lang
        },
        //toggleLanguage(lang) {
        //    this.currentLanguage = lang //this.currentLanguage === 'EN' ? 'CZ' : 'EN'
        //    this.switchLanguage(lang) //(this.currentLanguage === 'en' ? 'cs' : 'en')
        //    // Kód pro změnu jazyka ve vaší aplikaci
        //},
    },
}
</script>
