import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIyMDIzIiwidXNlcm5hbWUiOiJjb3NfdHJhY2luZ19hcGlfYWRtaW4iLCJpYXQiOjE2OTIwNDE0ODV9.0p9UJ3iatHQPdnj1QHvOd6k5iNH2G9wi8es0-acbiZU',
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {},
})
