import axios from 'axios'

const apiClient = axios.create({
    //baseURL: 'https://www.edinaut.com/apizachtrans',
    //baseURL: 'http://92.240.254.133:8000'
    baseURL: process.env.VUE_APP_API_URL,
    //headers: {
    //    Accept: 'application/json',
    //    'content-type': 'application/json'
    //}
})

export default {
    setAuthorizationHeader(bearer) {
        apiClient.defaults.headers.common['Authorization'] = bearer
    },
    callSearch(par) {
        return apiClient.get('/tracing', par)
    },
}
